import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import Hero1 from '../components/hero1'
import VideoComponent from '../components/video-component'
import AboutUs from '../components/about-us'
import CaseStory from '../components/case-story'
import ImageSlider from '../components/image-slider'
import Servizi from '../components/servizi'
import Partners from '../components/partners'
import Contattaci from '../components/contattaci'
import Footer from '../components/footer'
import './home.css'
import LoadingAnimation from '../components/loadingAnimation';

const Home = (props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const homeCaseStoryRef = useRef();
  const [isScrollButtonVisible, setScrollButtonVisible] = useState(true);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - homeCaseStoryRef.current.offsetLeft);
    setScrollLeft(homeCaseStoryRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - homeCaseStoryRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    homeCaseStoryRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleHomeScrollButtonClick = () => {
    homeCaseStoryRef.current.scrollBy({ left: 3000, behavior: 'smooth' });
  };

  const checkScrollEnd = () => {
    const { scrollWidth, clientWidth, scrollLeft } = homeCaseStoryRef.current;
    if (scrollLeft + clientWidth >= scrollWidth) {
      setScrollButtonVisible(false);
    } else {
      setScrollButtonVisible(true);
    }
  }

  useEffect(() => {
    const checkIfInView = () => {
      const rect = homeCaseStoryRef.current.getBoundingClientRect();
      const isInView = rect.top <= window.innerHeight / 3 && rect.bottom >= window.innerHeight / 1.5;
      setIsVisible(isInView);
    };

    checkIfInView();
    window.addEventListener('scroll', checkIfInView);
    homeCaseStoryRef.current.addEventListener('scroll', checkScrollEnd);

    return () => {
      window.removeEventListener('scroll', checkIfInView);
      homeCaseStoryRef.current.removeEventListener('scroll', checkScrollEnd);
    };
  }, []);

  return (
    <div className="home-container">
        <LoadingAnimation />
        <Helmet>
          <title>Domini Agency</title>
          <meta property="og:title" content="Domini Agency" />
        </Helmet>
        <Hero1 rootClassName="hero1-root-class-name"></Hero1>
        <VideoComponent rootClassName="video-component-root-class-name"></VideoComponent>
        <AboutUs  rootClassName="about-us-root-class-name"></AboutUs>
        <CaseStory  rootClassName="case-story-root-class-name"></CaseStory>
        <div 
          ref={homeCaseStoryRef}  
          className="home-case-story" 
          onMouseDown={handleMouseDown} 
          onMouseLeave={handleMouseLeave} 
          onMouseUp={handleMouseUp} 
          onMouseMove={handleMouseMove}
        >
          <ImageSlider rootClassName="image-slider-root-class-name1"></ImageSlider>
          <div 
            className={`home-scroll-button ${isVisible && isScrollButtonVisible ? 'visible' : 'hidden'}`} 
            onClick={handleHomeScrollButtonClick}
          ></div>
        </div>
        <Servizi  rootClassName="servizi-root-class-name"></Servizi>
        <Partners rootClassName="partners-root-class-name"></Partners>
        <Contattaci  rootClassName="contattaci-root-class-name"></Contattaci>
        <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default Home
