import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"
import "./servizi.css"
import Marquee from "react-fast-marquee"
import { createPortal } from "react-dom"
import videoFile from "./video/video_presentazione.mp4"
const Popup = ({ onClose, show, popupContent }) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }

    return () => {
      document.body.classList.remove("no-scroll")
    }
  }, [show])

  if (!show) return null

  return createPortal(
    <div className="popup-container2 service-popup" onClick={onClose}>
      <div className="popup-content2" onClick={(e) => e.stopPropagation()}>
        <button className="popup-close2" onClick={onClose}>
          ✖
        </button>
        <div className="popcontentrotation">
          <div className="titlepopupdiv">
            <div className="popup-textNumber">{popupContent.number}</div>
            <div className="popup-textH1">{popupContent.title}</div>
          </div>
          {popupContent.texts.map((text, index) => (
            <div key={index} className="popup-textP">
              {text}
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.body
  )
}

const Servizi = (props) => {
  const [showPopup, setShowPopup] = useState(false)
  const [popupContent, setPopupContent] = useState(null)

  const popupContents = [
    {
      number: "01",
      title: "WEB AGENCY",
      texts: [
        "Risulta fondamentale per aziende, brand e professionisti proporre al proprio pubblico potenziale una vetrina dei propri servizi, prodotti ed offerte.",
        "Domini Agency supporta i propri partner facendo affidamento su un team di sviluppatori dedicati alla creazione di siti web, piattaforme gestionali ed APP, strizzando l’occhio alle più recenti implementazioni e garantendo un meticoloso controllo dell’ecosistema digitale che nel tempo viene costruito.",
      ],
    },
    {
      number: "02",
      title: "SOCIAL MEDIA MANAGEMENT",
      texts: [
        "È a conoscenza di tutti quanto l’attenzione del pubblico si sia spostata sui Social Media quali: Instagram, TikTok, YouTube, Pinterest, LinkedIn; le quali si declinano e vengono utilizzate secondo gli interessi, le abitudini e l’obiettivo di ogni utente. Il team composto interamente da nativi digitali si muove efficacemente nelle attività di posizionamento del Brand, sviluppo del piano comunicativo, creazione di piani editoriali, content creation e strategie di crescita proprio per queste piattaforme social.",
      ],
    },
    {
      number: "03",
      title: "INFLUENCER MARKETING",
      texts: [
        "La punta di diamante di Domini Agency si rivede nello sviluppo di campagne di Influencer marketing qualitative e performanti in termini di posizionamento e riconoscibilità online, soprattutto grazie ad un meticoloso lavoro di supporto proprio nei confronti di Content creator ed Influencer che decidono di affidarsi alla nostra agenzia, consapevoli che con il giusto team alle spalle nessun obiettivo diventa troppo grande per essere conseguito.",
      ],
    },
    // add other popup contents here
  ]

  const [scrollPercentage, setScrollPercentage] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
      const body = document.body
      const html = document.documentElement
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
      const windowBottom = windowHeight + window.pageYOffset
      const percentage = (windowBottom / docHeight) * 100

      setScrollPercentage(percentage)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      // Check if window is defined before trying to remove the event listener
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const calculateAnimation = (start, end) => {
    if (scrollPercentage < start) return 0
    if (scrollPercentage > end) return 100
    return ((scrollPercentage - start) * 100) / (end - start)
  }

  const webAgencyAnimation = calculateAnimation(20, 66)
  const influencerAnimation = calculateAnimation(40, 66)
  const socialMediaAnimation = calculateAnimation(60, 66)

  const ImageOverlay = () => {
    return (
      <div className="image-overlay">
        <div className="play-button">ⓘ</div>
      </div>
    )
  }

  return (
    <div id="servizi" className={`servizi-container ${props.rootClassName} `}>
      <Popup
        onClose={() => {
          setShowPopup(false)
          setPopupContent(null)
        }}
        show={showPopup}
        popupContent={popupContent}
      />

      <div className="servizi-striscia-sopra-servizi">
        <Marquee>
          <span className="servizi-servizi">
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
            <span className="">servizi</span>
            <br className=""></br>
          </span>
        </Marquee>
      </div>
      <div className="servizi-about-us-div1">
        <div className="servizi-about-child1">
          <div
            className="servizi-c1"
            style={{
              transform: `translateX(-${100 - webAgencyAnimation}%)`,
              transition: "transform 0.5s ease-out",
            }}
          >
            <span
              className="servizi-text126"
              onClick={() => {
                setShowPopup(true)
                setPopupContent(popupContents[0]) // or any content you want to display
              }}
            >
              WEB AGENCY
            </span>
            <img alt={props.image_alt2} src={props.image_src2} className="servizi-image" />

            {/* Change the click event here */}
            <div
              className="zoom-wrapper"
              onClick={() => {
                setShowPopup(true)
                setPopupContent(popupContents[0]) // or any content you want to display
              }}
            >
              <img alt={props.image_alt11} src={props.image_src11} className="servizi-image1 zoom-image" />
              <ImageOverlay />
            </div>

            {/* Repeat similar code for other clickable elements */}
            {/* Don't forget to change the popup content accordingly */}
          </div>
          <div
            className="servizi-c2"
            style={{
              transform: `translateX(-${100 - influencerAnimation}%)`,
              transition: "transform 0.5s ease-out",
            }}
          >
            <span
              className="servizi-text127"
              onClick={() => {
                setShowPopup(true)
                setPopupContent(popupContents[2]) // or any content you want to display
              }}
            >
              INFLUENCER MARKETING
            </span>
            <img alt={props.image_alt} src={props.image_src} className="servizi-image2" />

            {/* Change the click event here */}
            <div
              className="zoom-wrapper"
              onClick={() => {
                setShowPopup(true)
                setPopupContent(popupContents[2]) // or any content you want to display
              }}
            >
              <img alt={props.image_alt1} src={props.image_src1} className="servizi-image3 zoom-image" />
              <ImageOverlay />
            </div>
          </div>
          <div
            className="servizi-c3"
            style={{
              transform: `translateX(-${100 - socialMediaAnimation}%)`,
              transition: "transform 0.5s ease-out",
            }}
          >
            <span
              className="servizi-text128"
              onClick={() => {
                setShowPopup(true)
                setPopupContent(popupContents[1]) // or any content you want to display
              }}
            >
              SOCIAL MEDIA MANAGEMENT
            </span>
            <img alt={props.image_alt3} src={props.image_src3} className="servizi-image4" />

            {/* Change the click event here */}
            <div
              className="zoom-wrapper"
              onClick={() => {
                setShowPopup(true)
                setPopupContent(popupContents[1]) // or any content you want to display
              }}
            >
              <img alt={props.image_alt12} src={props.image_src12} className="servizi-image5 zoom-image" />
              <ImageOverlay />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Servizi.defaultProps = {
  image_alt2: "image",
  image_src3: "/playground_assets/ellisse%204-200h.png",
  image_alt1: "influencer marketing",
  image_src12: "/playground_assets/social.jpg",
  image_alt11: "web design",
  image_src2: "/playground_assets/ellisse%204-200h.png",
  rootClassName: "",
  image_alt12: "social media management",
  image_src11: "/playground_assets/design.jpg",
  image_src1: "/playground_assets/influencer.jpg",
  image_alt3: "image",
  image_alt: "image",
  image_src: "/playground_assets/ellisse%204-200h.png",
}

Servizi.propTypes = {
  image_alt2: PropTypes.string,
  image_src3: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src12: PropTypes.string,
  image_alt11: PropTypes.string,
  image_src2: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt12: PropTypes.string,
  image_src11: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt3: PropTypes.string,
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
}

export default Servizi
