import React, { useEffect, useState, Suspense, useRef } from "react"

import PropTypes from "prop-types"

import "./hero1.css"
import Marquee from "react-fast-marquee"
import LogoAnimated from "./logoanimated"
const Hero1 = (props) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [lastScrollPos, setLastScrollPos] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false) // State to control the visibility of the menu

  const handleScroll = () => {
    const scrollPos = window.scrollY
    const elements = document.querySelectorAll(".float-on-scroll")

    elements.forEach((element, index) => {
      const offset = index * 100 // You can change this value to adjust the difference between the elements
      const floatingValue = Math.sin((scrollPos + offset) / 100) * 10 // Adjust the 100 and 10 values to change the wave frequency and amplitude
      element.style.transform = `translateY(${floatingValue}px)`
    })

    setLastScrollPos(scrollPos)
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [lastScrollPos])

  const handleNavLinkClick = (sectionId) => {
    if (closeMenuRef.current) {
      closeMenuRef.current.click()
    }
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" })
  }
  const closeMenuRef = useRef(null)

  return (
    <div style={{ zIndex: "11" }} className={`hero1-container ${props.rootClassName} `}>
      <div className="hero1-hero-container1">
        <div className="hero1-contenuto-hero">
          <header data-thq="thq-navbar" className="hero1-navbar-interactive">
            <div className="hero1-logo-icone-social">
              <img alt="logo" src="/playground_assets/logo-domini-1x1.ico" className="hero1-logo" />
              <a href="https://www.instagram.com/domini.agency/?hl=en" target="_blank">
                <img alt="logo" src="/playground_assets/iconainstagram.svg" className="hero1-instagram" />
              </a>
              <a href="https://it.linkedin.com/company/domini-agency-srls" target="_blank">
                <img alt="logo" src="/playground_assets/iconalinkedin.svg" className="hero1-linkedin" />
              </a>

              <a href="https://wa.me/+393534226003" target="_blank">
                <img alt="logo" src="/playground_assets/iconawhatsapp.svg" className="hero1-whatsapp" />
              </a>
            </div>
            <div data-thq="thq-navbar-nav" data-role="Nav" className="hero1-desktop-menu">
              <nav data-thq="thq-navbar-nav-links" data-role="Nav" className="hero1-nav">
                <span className="">About</span>
                <span className="hero1-text001">Features</span>
                <span className="hero1-text002">Pricing</span>
                <span className="hero1-text003">Team</span>
                <span className="hero1-text004">Blog</span>
              </nav>
            </div>
            <div data-thq="thq-navbar-btn-group" className="hero1-btn-group">
              <button className="hero1-login button">Login</button>
              <button className="button">Register</button>
            </div>
            <div data-thq="thq-burger-menu" className="hero1-burger-menu" onClick={() => setIsMenuOpen(true)}>
              <img alt={props.image_alt} src={props.image_src} className="hero1-menu-icon" />
            </div>
            <div data-thq="thq-mobile-menu" className="hero1-mobile-menu" style={{ transform: isMenuOpen ? "none" : "translateX(100%)" }}>
              <div data-thq="thq-mobile-menu-nav" data-role="Nav" className="hero1-nav1">
                <div className="hero1-container1">
                  <div ref={closeMenuRef} data-thq="thq-close-menu" className="hero1-menu-close" onClick={() => setIsMenuOpen(false)}>
                    <svg viewBox="0 0 804.5714285714286 1024" className="hero1-icon">
                      <path
                        d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"
                        className=""
                      ></path>
                    </svg>
                  </div>
                </div>
                <nav data-thq="thq-mobile-menu-nav-links" data-role="Nav" className="hero1-nav2">
                  <span className="hero1-text006" onClick={() => handleNavLinkClick("about-us")}>
                    ABOUT US
                  </span>
                  <span className="hero1-text007" onClick={() => handleNavLinkClick("case-study")}>
                    CASE STUDY
                  </span>
                  <span className="hero1-text008" onClick={() => handleNavLinkClick("servizi")}>
                    SERVIZI
                  </span>
                  <span className="hero1-text009" onClick={() => handleNavLinkClick("contattaci")}>
                    CONTATTACI
                  </span>
                  <div className="hero1-container2">
                    <a href="https://www.instagram.com/domini.agency/?hl=en" target="_blank">
                      <img alt={props.image_alt11} src={props.image_src11} className="hero1-instagram1" />
                    </a>
                    <a href="https://it.linkedin.com/company/domini-agency-srls" target="_blank">
                      <img alt={props.image_alt112} src={props.image_src112} className="hero1-instagram2" />
                    </a>

                    <a href="https://wa.me/+393534226003" target="_blank">
                      <img alt={props.image_alt111} src={props.image_src111} className="hero1-instagram3" />
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </header>
          <div className="logo-animated-container">
            <LogoAnimated />
          </div>

          <div className="hero1-hero-h-div">
            <div className="hero1-heroh-part1">
              <span className="hero1-text010">LA TUA</span>

              <div className="hero1-container3"></div>
              <span className="hero1-text011">AGENZIA CREATIVA</span>
              <span className="hero1-text012">
                {/*
              CLAIM COMMENTATO
                <span className="">
                  Inserire claim per rappresentare in poche parole l’agenzia e i
                  servizi che offre
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="">al pubblico.</span>
               */}
              </span>
            </div>
            <div className="hero1-heroh-part2">
              <div className="hero1-text-right-hero float-on-scroll">
                <span className="hero1-text015">
                  <span className="hero1-text016">D</span>
                  <span className="hero1-text017">IGITAL</span>
                </span>
                <span className="hero1-text018">
                  <span className="hero1-text019">O</span>
                  <span className="hero1-text020">RIGINALITA</span>
                </span>
              </div>
              <div className="hero1-text-right-hero1 float-on-scroll">
                <span className="hero1-text021">
                  <span className="hero1-text022">M</span>
                  <span className="hero1-text023">ETODO</span>
                </span>
                <span className="hero1-text024">
                  <span className="hero1-text025">I</span>
                  <span className="hero1-text026">NNOVAZIONE</span>
                </span>
              </div>
              <div className="hero1-text-right-hero2 float-on-scroll">
                <span className="hero1-text027">
                  <span className="hero1-text028">N</span>
                  <span className="hero1-text029">ETWORK</span>
                </span>
                <span className="hero1-text030">
                  <span className="hero1-text031">I</span>
                  <span className="hero1-text032">SPIRAZIONE</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="hero1-striscia-sotto">
          <Marquee>
            <span className="servizi-servizi">
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
              <span className="">show reel</span>
              <br className=""></br>
            </span>
          </Marquee>
        </div>
      </div>
    </div>
  )
}

Hero1.defaultProps = {
  image_src11: "/playground_assets/raggruppa%20982-1500h.png",
  image_src112: "/playground_assets/raggruppa%204-1500h.png",
  rootClassName: "",
  image_alt: "image",
  image_alt11: "image",
  text: "",
  image_alt1: "image",
  image_src111: "/playground_assets/raggruppa%2027-1500h.png",
  text1: "Text",
  image_alt111: "image",
  image_src1: "https://play.teleporthq.io/static/svg/default-img.svg",
  image_src: "/playground_assets/iconamenu.svg",
  image_alt112: "image",
}

Hero1.propTypes = {
  image_src11: PropTypes.string,
  image_src112: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  image_alt11: PropTypes.string,
  text: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src111: PropTypes.string,
  text1: PropTypes.string,
  image_alt111: PropTypes.string,
  image_src1: PropTypes.string,
  image_src: PropTypes.string,
  image_alt112: PropTypes.string,
}

export default Hero1
