import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Home from './views/home'
import AnimatedCursor from "react-animated-cursor"
const App = () => {
  return (
    <Router>
      <div>
      <AnimatedCursor
  color="157,141,241"
  innerSize={8}
  outerSize={35}
  innerScale={1}
  outerScale={1.7}
  outerAlpha={0}
  outerStyle={{
    border: '3px solid #DAFF41'
  }}
/>
        <Route component={Home} exact path="/"  />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
