import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MathUtils } from 'three';

const LogoAnimated = () => {
  const mount = useRef();
  let mouseX = 0;
  let mouseY = 0;
  const windowHalfX = window.innerWidth / 2;
  const windowHalfY = window.innerHeight / 2;
  const isMobile = window.innerWidth <= 768; // Consider as a mobile device if the width is less than or equal to 768px
  const isBig = window.innerWidth <= 768; // Consider as a mobile device if the width is less than or equal to 768px
  // Function to handle mouse move event
  let targetRotationX = 0;
  let targetRotationY = 0;
  
  const onDocumentMouseMove = (event) => {
    mouseX = (event.clientX - windowHalfX) / (2 * windowHalfX); // normalized between -0.5 and 0.5
    mouseY = (event.clientY - windowHalfY) / (2 * windowHalfY); // normalized between -0.5 and 0.5
  
    // update target rotations based on mouse movements
    targetRotationX = Math.PI * mouseY;
    targetRotationY = Math.PI * mouseX;
  }
  

  useEffect(() => {
    document.addEventListener('mousemove', onDocumentMouseMove, false);
    
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;

    // Add lighting
    const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
    scene.add(ambientLight);
    const pointLight = new THREE.PointLight(0xffffff, 0.8);
    camera.add(pointLight);
    scene.add(camera);

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true }); // alpha: true for transparent background
    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.current.appendChild(renderer.domElement);

    const loader = new GLTFLoader();
    let model;
    loader.load('/domini.gltf', function (gltf) {
      model = gltf.scene;
      model.scale.set(isMobile ? 34 : 34, isMobile ? 34 : 34, isMobile ? 34 : 34);  // Set the size of the model
      model.rotation.z = MathUtils.degToRad(8); // Rotate 8 degrees to the left

      scene.add(model);
    }, undefined, function (error) {
      console.error(error);
    });

    // Adjust camera and renderer on window resize
    const handleWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    window.addEventListener('resize', handleWindowResize);

    let start = null;
    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
    
      if (progress > 3500) { // After 4 seconds
        if (model) {
          // gradually update model rotation towards target rotation
          model.rotation.y += (targetRotationY - model.rotation.y) * 0.1;
          model.rotation.x += (targetRotationX - model.rotation.x) * 0.1;
          model.position.y = Math.sin(progress / 1000) / 4; // Float up and down
        }
      }

      camera.position.x += (mouseX - camera.position.x) * 0.05; // Spin based on cursor direction
      camera.position.y += (-mouseY - camera.position.y) * 0.05;
      camera.lookAt(scene.position);

      renderer.render(scene, camera);
      requestAnimationFrame(animate)
    };
    animate();

    // Clean up on unmount
    return () => {
      document.removeEventListener('mousemove', onDocumentMouseMove, false);
      window.removeEventListener('resize', handleWindowResize);
      mount.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mount}  className='logoDominiHero' style={{ zIndex: -10,position: 'absolute', top: 100, left: 0, width: '100%', height: '100%',  }} />;
};

export default LogoAnimated;
