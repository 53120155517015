import React from 'react'

import PropTypes from 'prop-types'

import './partners.css'
import Marquee from "react-fast-marquee";
const Partners = (props) => {
  return (
    <div className={`partners-container ${props.rootClassName} `}>
     <div className="servizi-striscia-sopra-servizi">
      <Marquee>
  

      <span className="servizi-servizi">
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
          <span className="">partners</span>
          <br className=""></br>
        </span>
        </Marquee>
      </div>
      <div className="partners-partners-div1">
      <Marquee direction='right'>
  
        <div className="partners-partners-c1">
          <img
            alt={props.image_alt4}
            src="/playground_assets/raggruppa%2091-1500h.png"
            className="partners-partner"
          />
          <img
            alt={props.image_alt47}
            src="/playground_assets/raggruppa%2090-1500h.png"
            className="partners-partner01"
          />
          <img
            alt={props.image_alt46}
            src="/playground_assets/raggruppa%2089-1500h.png"
            className="partners-partner02"
          />
          <img
            alt={props.image_alt45}
            src="/playground_assets/raggruppa%2088-1500h.png"
            className="partners-partner03"
          />
          <img
            alt={props.image_alt44}
            src="/playground_assets/raggruppa%2087-1500h.png"
            className="partners-partner04"
          />
             <img
            alt={props.image_alt4}
            src="/playground_assets/raggruppa%2091-1500h.png"
            className="partners-partner"
          />
          <img
            alt={props.image_alt47}
            src="/playground_assets/raggruppa%2090-1500h.png"
            className="partners-partner01"
          />
          <img
            alt={props.image_alt46}
            src="/playground_assets/raggruppa%2089-1500h.png"
            className="partners-partner02"
          />
          <img
            alt={props.image_alt45}
            src="/playground_assets/raggruppa%2088-1500h.png"
            className="partners-partner03"
          />
          <img
            alt={props.image_alt44}
            src="/playground_assets/raggruppa%2087-1500h.png"
            className="partners-partner04"
          />
             <img
            alt={props.image_alt4}
            src="/playground_assets/raggruppa%2091-1500h.png"
            className="partners-partner"
          />
          <img
            alt={props.image_alt47}
            src="/playground_assets/raggruppa%2090-1500h.png"
            className="partners-partner01"
          />
          <img
            alt={props.image_alt46}
            src="/playground_assets/raggruppa%2089-1500h.png"
            className="partners-partner02"
          />
          <img
            alt={props.image_alt45}
            src="/playground_assets/raggruppa%2088-1500h.png"
            className="partners-partner03"
          />
          <img
            alt={props.image_alt44}
            src="/playground_assets/raggruppa%2087-1500h.png"
            className="partners-partner04"
          />
           <img
            alt={props.image_alt4}
            src="/playground_assets/raggruppa%2091-1500h.png"
            className="partners-partner"
          />
          <img
            alt={props.image_alt47}
            src="/playground_assets/raggruppa%2090-1500h.png"
            className="partners-partner01"
          />
          <img
            alt={props.image_alt46}
            src="/playground_assets/raggruppa%2089-1500h.png"
            className="partners-partner02"
          />
          <img
            alt={props.image_alt45}
            src="/playground_assets/raggruppa%2088-1500h.png"
            className="partners-partner03"
          />
          <img
          style={{ marginRight:'100px'}}
            alt={props.image_alt44}
            src="/playground_assets/raggruppa%2087-1500h.png"
            className="partners-partner04"
          />
        </div>
        </Marquee>
        <Marquee direction='left'>
        <div className="partners-partners-c1">
          <img
            alt={props.image_alt48}
            src="/playground_assets/raggruppa%2096-1500h.png"
            className="partners-partner05"
          />
          <img
            alt={props.image_alt471}
            src="/playground_assets/raggruppa%2095-1500h.png"
            className="partners-partner06"
          />
          <img
            alt={props.image_alt461}
            src="/playground_assets/raggruppa%2094-1500h.png"
            className="partners-partner07"
          />
          <img
            alt={props.image_alt451}
            src="/playground_assets/raggruppa%2093-1500h.png"
            className="partners-partner08"
          />
          <img
            alt={props.image_alt441}
            src="/playground_assets/raggruppa%2092-1500h.png"
            className="partners-partner09"
          />
           <img
            alt={props.image_alt48}
            src="/playground_assets/raggruppa%2096-1500h.png"
            className="partners-partner05"
          />
          <img
            alt={props.image_alt471}
            src="/playground_assets/raggruppa%2095-1500h.png"
            className="partners-partner06"
          />
          <img
            alt={props.image_alt461}
            src="/playground_assets/raggruppa%2094-1500h.png"
            className="partners-partner07"
          />
          <img
            alt={props.image_alt451}
            src="/playground_assets/raggruppa%2093-1500h.png"
            className="partners-partner08"
          />
          <img
            alt={props.image_alt441}
            src="/playground_assets/raggruppa%2092-1500h.png"
            className="partners-partner09"
          />
           <img
            alt={props.image_alt48}
            src="/playground_assets/raggruppa%2096-1500h.png"
            className="partners-partner05"
          />
          <img
            alt={props.image_alt471}
            src="/playground_assets/raggruppa%2095-1500h.png"
            className="partners-partner06"
          />
          <img
            alt={props.image_alt461}
            src="/playground_assets/raggruppa%2094-1500h.png"
            className="partners-partner07"
          />
          <img
            alt={props.image_alt451}
            src="/playground_assets/raggruppa%2093-1500h.png"
            className="partners-partner08"
          />
          <img
            alt={props.image_alt441}
            src="/playground_assets/raggruppa%2092-1500h.png"
            className="partners-partner09"
          />
           <img
            alt={props.image_alt48}
            src="/playground_assets/raggruppa%2096-1500h.png"
            className="partners-partner05"
          />
          <img
            alt={props.image_alt471}
            src="/playground_assets/raggruppa%2095-1500h.png"
            className="partners-partner06"
          />
          <img
            alt={props.image_alt461}
            src="/playground_assets/raggruppa%2094-1500h.png"
            className="partners-partner07"
          />
          <img
         
            alt={props.image_alt451}
            src="/playground_assets/raggruppa%2093-1500h.png"
            className="partners-partner08"
          />
          <img style={{ marginRight:'100px'}}
            alt={props.image_alt441}
            src="/playground_assets/raggruppa%2092-1500h.png"
            className="partners-partner09"
          />
        </div>
        </Marquee>
      </div>
    </div>
  )
}

Partners.defaultProps = {
  image_alt461: 'image',
  image_src41: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image_alt451: 'image',
  image_alt3: 'image',
  image_alt42: 'image',
  image_alt2: 'image',
  image_alt45: 'image',
  image_alt48: 'image',
  image_src43: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image_src11: '6cb56048-b758-4811-b40a-43216a6cd41c',
  image_src42: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image_alt471: 'image',
  image_alt43: 'image',
  image_src: '80aa9dba-a591-4483-a763-c4a9dec93c76',
  image_src12: 'ebca5e4c-43f0-4c7f-b233-4e9b8c390c02',
  image_alt: 'image',
  rootClassName: '',
  image_alt1: 'image',
  image_alt12: 'image',
  image_alt47: 'image',
  image_alt44: 'image',
  image_alt41: 'image',
  image_src4: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image_src2: '80aa9dba-a591-4483-a763-c4a9dec93c76',
  image_alt4: 'image',
  image_alt11: 'image',
  image_alt441: 'image',
  image_src3: '80aa9dba-a591-4483-a763-c4a9dec93c76',
  image_src1: '98c3af5b-7d09-432f-b6d9-42a66b7d2dca',
  image_alt46: 'image',
}

Partners.propTypes = {
  image_alt461: PropTypes.string,
  image_src41: PropTypes.string,
  image_alt451: PropTypes.string,
  image_alt3: PropTypes.string,
  image_alt42: PropTypes.string,
  image_alt2: PropTypes.string,
  image_alt45: PropTypes.string,
  image_alt48: PropTypes.string,
  image_src43: PropTypes.string,
  image_src11: PropTypes.string,
  image_src42: PropTypes.string,
  image_alt471: PropTypes.string,
  image_alt43: PropTypes.string,
  image_src: PropTypes.string,
  image_src12: PropTypes.string,
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt1: PropTypes.string,
  image_alt12: PropTypes.string,
  image_alt47: PropTypes.string,
  image_alt44: PropTypes.string,
  image_alt41: PropTypes.string,
  image_src4: PropTypes.string,
  image_src2: PropTypes.string,
  image_alt4: PropTypes.string,
  image_alt11: PropTypes.string,
  image_alt441: PropTypes.string,
  image_src3: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt46: PropTypes.string,
}

export default Partners
