import React, { useState } from "react"

import PropTypes from "prop-types"

import "./image-slider.css"

const Popup = ({ togglePopup, popupContent }) => (
  <div className="popup-container2 case-popup" onClick={togglePopup}>
    <div className="popup-content2" onClick={(e) => e.stopPropagation()}>
      <button className="popup-close2" onClick={togglePopup}>
        ✖
      </button>
      <div className="popcontentrotation">
        <div className="titlepopupdiv">
          <div className="popup-textNumber">{popupContent.number}</div>
          <div className="popup-textH1">{popupContent.title}</div>
        </div>
        {popupContent.texts.map((text, index) => (
          <div key={index} className="popup-textP">
            {text}
          </div>
        ))}
      </div>
    </div>
  </div>
)

const ImageContainer = ({ togglePopup, slideContent }) => (
  <div
    onClick={togglePopup}
    className="image-slider-slide-div1"
    style={{ backgroundImage: 'url("/playground_assets/' + slideContent.image + '")' }}
  >
    <div className="image-slider-div-c1">
      <span className="image-slider-text">
        <span>{slideContent.number}</span>
        <br />
        <br />
      </span>
      <span className="image-slider-text04">
        <span>{slideContent.title}</span>
        <br />
      </span>
      <div className="image-slider-ellisse"></div>
    </div>
    <div className="image-slider-div1c2" style={{ width: slideContent.descriptionWidth }}>
      <span className="image-slider-text07">{slideContent.description}</span>
    </div>
  </div>
)

const ImageSlider = (props) => {
  const [popupVisible, setPopupVisible] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(null)

  const togglePopup = (slide) => {
    setCurrentSlide(slide)
    setPopupVisible(!popupVisible)
  }

  const slides = [
    {
      number: "01",
      title: "",
      description: "NATÚ",
      descriptionWidth: "160px",
      image: "natu.jpg",
      popupContent: {
        number: "01",
        title: "NATÚ",
        texts: [
          "Abbiamo partecipato a Rimini Wellness 2023, supportando Natù di Rigoni di Asiago.  Missanne_fit, esperta di fitness e benessere, è  stata protagonista presso lo stand del brand, condividendo la sua esperienza con la Community di Natù, Rimini Wellness e la propria.",
          "L’attenzione è stata posta sul posizionamento del brand nell'industria del wellbeing e sull’importanza di sfruttare i canali social e il supporto di esperti con il proprio expertise.",
          // Add more texts here
        ],
      },
    },
    {
      number: "02",
      title: "",
      description: "EMMA MATERASSO",
      descriptionWidth: "410px",
      image: "emma.jpeg",
      popupContent: {
        number: "02",
        title: "EMMA MATERASSO",
        texts: [
          "Spesso sacrifichiamo la qualità del nostro sonno pur di evitare l'acquisto di un nuovo materasso. Con Emma Materasso, un brand tedesco, e le influencer di Domini Agency, abbiamo presentato una soluzione ideale in termini di costo e di esperienza d'acquisto. Attraverso la creazione di contenuti su Instagram, abbiamo esaltato le caratteristiche vincenti del brand. I nostri obiettivi comprendevano il posizionamento del brand su Instagram e la ricerca di conversioni tramite influencer selezionate in base allo stile, alle abitudini e al pubblico ideale di Emma Materasso.",
          // Add more texts here
        ],
      },
    },
    {
      number: "03",
      title: "",
      description: "WATERDROP",
      descriptionWidth: "290px",
      image: "waterdrop.jpeg",
      popupContent: {
        number: "03",
        title: "WATERDROP",
        texts: [
          "In quanto start-up innovativa, Domini Agency, si concentra sulle soluzioni ecologiche e innovative che propone il mercato. Questo è il caso di Waterdrop, che con le sue compresse aromatizzate senza zucchero, promuove l'idratazione e offre un'alternativa salutare alle bevande dolcificate.",
          "Grazie all'entusiasmo delle influencer di Domini Agency, è nata una campagna di influencer marketing vincente, con contenuti di alta qualità che coinvolgono la loro community. Questa collaborazione ha potenziato la presenza del brand su Instagram, migliorando la riconoscibilità e l'esperienza di acquisto online.",
          // Add more texts here
        ],
      },
    },
    {
      number: "04",
      title: "",
      description: "SITLY",
      descriptionWidth: "290px",
      image: "sitly.jpeg",
      popupContent: {
        number: "04",
        title: "SITLY",
        texts: [
          "Domini Agency ha giocato un ruolo chiave nella partnership con Sitly, specializzata nell'unire famiglie e babysitter. La selezione accurata di content creators allineati ai valori di Sitly ha garantito una connessione autentica con il brand, amplificando la presenza online e rafforzando la fiducia degli utenti. La promozione dinamica attraverso i content creators di Domini ha notevolmente aumentato la consapevolezza del brand. Questa collaborazione ha evidenziato l'importanza cruciale della scelta accurata di creatori di contenuti nel creare connessioni autentiche, sottolineando il ruolo strategico di Domini Agency nel successo di Sitly.",
          // Add more texts here
        ],
      },
    },
    {
      number: "05",
      title: "",
      description: "WOMEN'S BEST",
      descriptionWidth: "290px",
      image: "womens.jpeg",
      popupContent: {
        number: "05",
        title: "WOMEN'S BEST",
        texts: [
          "Domini Agency ha forgiato una partnership strategica tra Women's Best e l'influencer fitness @missanne_fit, creando una sinergia nel wellness. L'agenzia ha agito da intermediario, selezionando attentamente Miss Anne per rappresentare i valori del marchio. La collaborazione annuale ha coinvolto una pianificazione precisa dei contenuti, garantendo coerenza su Instagram. Quest'approccio ha generato un racconto visivo coinvolgente, consolidando l'immagine di Women's Best nel fitness.",
          // Add more texts here
        ],
      },
    },

    {
      number: "06",
      title: "",
      description: "MYPROTEIN",
      descriptionWidth: "290px",
      image: "myprotein.jpg",
      popupContent: {
        number: "06",
        title: "MYPROTEIN",
        texts: [
          "Domini Agency ha orchestrato la partnership tra Myprotein e @smartisfitness, influencer fitness. L'agenzia è stata cruciale come intermediario, facilitando una connessione significativa. La collaborazione ha prodotto contenuti dinamici e coinvolgenti, con pianificazione accurata per una presenza costante su Instagram. Domini Agency ha riflettuto l'impegno di Myprotein per uno stile di vita attivo. Il risultato è stato un'affermazione di fitness e benessere, unendo l'influencer marketing alla qualità dei prodotti Myprotein. La partnership, gestita con maestria da Domini Agency, ha consolidato la posizione di Myprotein nel settore del fitness e del benessere.",
          // Add more texts here
        ],
      },
    },

    {
      number: "07",
      title: "",
      description: "CÉRÉAL",
      descriptionWidth: "290px",
      image: "cereal.jpeg",
      popupContent: {
        number: "07",
        title: "CÉRÉAL",
        texts: [
          "Domini Agency ha orchestrato una partnership tra Céréal e @dietista.martacivettini, specializzata in celiachia, per promuovere la salute e la consapevolezza alimentare. L'agenzia è stata chiave come intermediario, selezionando l'influencer in linea con la missione di Céréal. La collaborazione annuale ha visto la creazione regolare di contenuti su Instagram, consentendo a Céréal di condividere preziose informazioni nutrizionali attraverso @dietista.martacivettini. Questo approccio ha consolidato la posizione del marchio come consapevole e responsabile nel settore alimentare, contribuendo al successo della campagna e alla consapevolezza del brand.",
          // Add more texts here
        ],
      },
    },

    {
      number: "08",
      title: "",
      description: "LOACKER",
      descriptionWidth: "290px",
      image: "loacker.jpeg",
      popupContent: {
        number: "08",
        title: "LOACKER",
        texts: [
          "Loacker e Domini Agency hanno collaborato in un evento unico, concentrato su benessere e condivisione. Loacker ha organizzato un evento fisico coinvolgendo influencer del wellness per esprimere concretamente i suoi valori. Domini Agency ha gestito la selezione attenta dei content creators, garantendo un allineamento perfetto con Loacker. @luciapizzolato e @fit_clear sono stati scelti come autentici portavoce del brand. Il risultato è stato un evento coinvolgente e autentico, dimostrando che l'integrazione di valori condivisi e la scelta accurata degli influencer possono trasformare un evento in un racconto memorabile, riflettendo l'essenza del brand.",
          // Add more texts here
        ],
      },
    },
    // Add more slides here
  ]

  return (
    <div className={`image-slider-container ${props.rootClassName}`}>
      <div className="image-slider-slide">
        {slides.map((slide, index) => (
          <ImageContainer key={index} slideContent={slide} togglePopup={() => togglePopup(slide)} />
        ))}
        {popupVisible && currentSlide && <Popup togglePopup={togglePopup} popupContent={currentSlide.popupContent} />}
      </div>
    </div>
  )
}

ImageSlider.defaultProps = {
  text1: "Text",
  rootClassName: "",
  text: "Text",
}

ImageSlider.propTypes = {
  text1: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default ImageSlider
