import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'

import './contattaci.css'
import Marquee from "react-fast-marquee";
import Poly4 from './images/Poly4.png';
import Poly5 from './images/Poly5.png';
import Poly6 from './images/Poly6.png';

const Contattaci = (props) => {

  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [floatPosition, setFloatPosition] = useState({ x: 0, y: 0 });
  const animationFrameRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      const windowBottom = windowHeight + window.pageYOffset;
      const percentage = (windowBottom / docHeight) * 100;

      setScrollPercentage(percentage);
    };

    const updatePosition = () => {
      setFloatPosition({
        x: Math.sin(Date.now() / 1000) * 10,
        y: Math.cos(Date.now() / 1000) * 10,
      });
      animationFrameRef.current = requestAnimationFrame(updatePosition);
    };

    window.addEventListener('scroll', handleScroll);
    animationFrameRef.current = requestAnimationFrame(updatePosition);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, []);

  const calculateAnimation = (start, end) => {
    if (scrollPercentage < start) return 0;
    if (scrollPercentage > end) return 100;
    return ((scrollPercentage - start) * 100) / (end - start);
  };

  const poly4Animation = calculateAnimation(0, 33);
  const poly5Animation = calculateAnimation(33, 66);
  const poly6Animation = calculateAnimation(66, 100);
  return (
    <div id="contattaci" className={`contattaci-container ${props.rootClassName} `}>
      <div className="contattaci-striscia-sopra-contattaci">
      <Marquee>
      <span className="servizi-servizi">
      <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
          <span className="">contattaci</span>
          <br className=""></br>
      </span>
      </Marquee>
      </div>
      <div className="contattaci-contattaci-div1"  style={{ position: 'relative' }}>


      <div style={{ 
      backgroundImage: `url(${Poly4})`,
      transform: `translate(${floatPosition.x}px, ${poly6Animation*7 + floatPosition.y}%) scale(${1 + poly6Animation / 300})`,
      position: 'absolute',
      width: '77px',
      height: '77px',
      backgroundSize: 'cover',
      top: '-10%',
      left: '35%',
      zIndex: '-10'
    }}/>
        <div style={{ 
          backgroundImage: `url(${Poly5})`,
          transform: `translate(${floatPosition.y}px, ${poly6Animation*4 - floatPosition.x}%) scale(${1 + poly6Animation / 300})`,
          position: 'absolute',
          width: '130px',
          height: '130px',
          backgroundSize: 'cover',
          top: '40%',
          right: '20%',
          zIndex: '-10'
        }}/>
        <div style={{ 
          backgroundImage: `url(${Poly6})`,
          transform: `translate(${floatPosition.y}px, ${poly6Animation*4 - floatPosition.x}%) scale(${1 + poly6Animation / 300})`,
          position: 'absolute',
          width: '126px',
          height: '135px',
          backgroundSize: 'cover',
          bottom: '65%',
          right: '70%',
          zIndex: '-10'
        }}/>

        <div className="contattaci-contattaci-c1">
          <span className="contattaci-text88">
            <span className="">
              DOMINI È LA 
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="contattaci-text90">SOLUZIONE</span>
            <br className="contattaci-text91"></br>
            <br className=""></br>
            <span className="">CHE CERCHI</span>
          </span>
          <div className="contattaci-contattaci-button">
          <a href="https://wa.me/+393534226003" target="_blank">
            <span className="contattaci-text94">CONTATTACI</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

Contattaci.defaultProps = {
  button: 'Button',
  rootClassName: '',
  text: 'Text',
}

Contattaci.propTypes = {
  button: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default Contattaci
