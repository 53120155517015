import React, { useRef, useState, useEffect } from "react"
import * as THREE from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

const LoadingAnimation = () => {
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [animationComplete, setAnimationComplete] = useState(false)
  const mount = useRef()
  const sceneRef = useRef()
  const rendererRef = useRef()
  const rotationSpeedRef = useRef(0.003) // Initial rotation speed
  const initialPhaseDuration = 2000 // Duration of the initial phase in milliseconds
  const fastSpinDelay = 1000 // Delay before the fast spinning starts in milliseconds

  useEffect(() => {
    const scene = new THREE.Scene()
    scene.background = null // Set background color to transparent
    sceneRef.current = scene

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.position.z = 5

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }) // Enable alpha for transparent background
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setClearColor(0x000000, 0) // Set clear color to transparent
    rendererRef.current = renderer

    mount.current.appendChild(renderer.domElement)

    // Add lighting
    const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4)
    scene.add(ambientLight)
    const pointLight = new THREE.PointLight(0xffffff, 0.8)
    camera.add(pointLight)
    scene.add(camera)

    const loader = new GLTFLoader()
    loader.load(
      "/domini.gltf",
      function (gltf) {
        gltf.scene.scale.set(44, 44, 44)
        const model = gltf.scene
        scene.add(model)

        const animate = function () {
          requestAnimationFrame(animate)

          model.rotation.y += rotationSpeedRef.current

          renderer.render(scene, camera)
        }

        animate()
      },
      undefined,
      function (error) {
        console.error(error)
      }
    )

    return () => {
      mount.current.removeChild(renderer.domElement)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 6
        } else {
          setTimeout(() => {
            rotationSpeedRef.current = 0.03 // Faster rotation speed
            setAnimationComplete(true)
          }, fastSpinDelay)
          return 100
        }
      })
    }, 100)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={`loading-container ${animationComplete ? "animation-complete" : ""} scroll-animation`}>
      <div className="loading-text first-timeout">
        <span className="letter">D</span>
        <span className="letter">o</span>
        <span className="letter">m</span>
        <span className="letter">i</span>
        <span className="letter">n</span>
        <span className="letter">i</span>
      </div>
      <div className="loading-text second-timeout">
        <span className="letter">A</span>
        <span className="letter">g</span>
        <span className="letter">e</span>
        <span className="letter">n</span>
        <span className="letter">c</span>
        <span className="letter">y</span>
      </div>
      <div ref={mount} className="loading-model" />
    </div>
  )
}

export default LoadingAnimation
