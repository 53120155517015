import React from 'react'

import PropTypes from 'prop-types'

import './case-story.css'
import Marquee from "react-fast-marquee";
const CaseStory = (props) => {
  return (
    <div id="case-study" className={`case-story-container ${props.rootClassName} `}>
      <div className="case-story-striscia-sotto-about">
      <Marquee>
      <span className="servizi-servizi">
      <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          <span className="">case story</span>
          <br className=""></br>
          
        </span>
      </Marquee>
      </div>
      <div className="case-story-about-us-div1"></div>
    </div>
  )
}

CaseStory.defaultProps = {
  rootClassName: '',
}

CaseStory.propTypes = {
  rootClassName: PropTypes.string,
}

export default CaseStory
