import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import "./about-us.css"
import Marquee from "react-fast-marquee"
import Poly1 from "./images/Poly1.png"
import Poly2 from "./images/Poly2.png"
import Poly3 from "./images/Poly3.png"

const AboutUs = (props) => {
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [floatPosition, setFloatPosition] = useState({ x: 0, y: 0 })

  const animationFrameRef = useRef()

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
      const body = document.body
      const html = document.documentElement
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
      const windowBottom = windowHeight + window.pageYOffset
      const percentage = (windowBottom / docHeight) * 70

      setScrollPercentage(percentage)
    }

    const updatePosition = () => {
      setFloatPosition({
        x: Math.sin(Date.now() / 1000) * 10,
        y: Math.cos(Date.now() / 1000) * 10,
      })
      animationFrameRef.current = requestAnimationFrame(updatePosition)
    }

    window.addEventListener("scroll", handleScroll)
    animationFrameRef.current = requestAnimationFrame(updatePosition)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      cancelAnimationFrame(animationFrameRef.current)
    }
  }, [])

  const calculateAnimation = (start, end) => {
    if (scrollPercentage < start) return 0
    if (scrollPercentage > end) return 100
    return ((scrollPercentage - start) * 100) / (end - start)
  }

  const poly1Animation = calculateAnimation(0, 33)
  const poly2Animation = calculateAnimation(33, 66)
  const poly3Animation = calculateAnimation(66, 100)

  return (
    <div id="about-us" className={`about-us-container ${props.rootClassName} `}>
      <div className="about-us-striscia-sopra-about">
        <Marquee>
          <span className="servizi-servizi">
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
            <span className="">about us</span>
            <br className=""></br>
          </span>
        </Marquee>
      </div>
      <div className="about-us-about-us-div1" style={{ position: "relative" }}>
        <div
          style={{
            backgroundImage: `url(${Poly1})`,
            transform: `translate(${floatPosition.x}px, ${poly2Animation * 4 + floatPosition.y}%) scale(${1 + poly2Animation / 300})`,
            position: "absolute",
            width: "265px",
            height: "265px",
            backgroundSize: "cover",
            top: "10%",
            right: "500px",
            zIndex: "-10",
          }}
        />
        <div
          style={{
            backgroundImage: `url(${Poly2})`,
            transform: `translate(${floatPosition.y}px, ${poly2Animation * 4 - floatPosition.x}%) scale(${1 + poly2Animation / 300})`,
            position: "absolute",
            width: "268px",
            height: "268px",
            backgroundSize: "cover",
            top: "50%",
            right: "500px",
            zIndex: "-10",
          }}
        />
        <div
          style={{
            backgroundImage: `url(${Poly3})`,
            transform: `translate(${floatPosition.x}px, ${poly2Animation * 4 + floatPosition.y}%) scale(${1 + poly2Animation / 300})`,
            position: "absolute",
            width: "126px",
            height: "135px",
            backgroundSize: "cover",
            top: "20%",
            left: "230px",
            zIndex: "-10",
          }}
        />
        <div className="about-us-about-child1">
          <span className="about-us-text80">
            <span className="about-us-text81">
              Ci muoviamo con disinvoltura su molte aree perché,
              <span dangerouslySetInnerHTML={{ __html: " " }}></span>
            </span>
            <span className="about-us-text83">
              nel rispetto di tutti i cliché, siamo multitasking.
              <span dangerouslySetInnerHTML={{ __html: " " }}></span>
            </span>
            <br className="about-us-text84" />
            <span className="about-us-text85">Un’Agenzia di </span>
            <span className="about-us-text86">Influencer Marketing</span>
            <span className="about-us-text87">
              , di produzione Web, di idee innovative. Questi concetti diventano realtà, i sogni diventano fatti e le storie diventano contenuti
              coinvolgenti.
              <span dangerouslySetInnerHTML={{ __html: " " }}></span>
            </span>
            <span className="about-us-text88">Siamo propositivi</span>
            <span className="about-us-text89">
              {" "}
              per indole,
              <span dangerouslySetInnerHTML={{ __html: " " }}></span>
            </span>
            <span className="about-us-text90">creativi</span>
            <span className="about-us-text91"> per definizione e credibili per chi ci ha scelto.</span>
          </span>
        </div>
      </div>
    </div>
  )
}

AboutUs.defaultProps = {
  rootClassName: "",
  text: "Text",
}

AboutUs.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default AboutUs
