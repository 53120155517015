import React from "react"

import PropTypes from "prop-types"

import "./video-component.css"
import videoFile from "./video/video_presentazione.mp4"

const VideoComponent = (props) => {
  return (
    <div className={`video-component-container ${props.rootClassName}`}>
      <div className="video-component-video-div1">
        <video autoPlay playsInline loop preload="metadata" muted className="video-component-background-video">
          <source src={videoFile} type="video/mp4" />
        </video>
        <img alt={props.image_alt} src={props.image_src} className="video-component-video-icona1" />
        <img alt={props.image_alt1} src={props.image_src1} className="video-component-video-icona2" />
      </div>
    </div>
  )
}

VideoComponent.defaultProps = {
  image_alt1: "image",
  rootClassName: "",
  image_src1: "/playground_assets/videoicona1.svg",
  image_src: "/playground_assets/videoicona2.svg",
  image_alt: "image",
}

VideoComponent.propTypes = {
  image_alt1: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src1: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
}

export default VideoComponent
