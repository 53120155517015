import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import "./footer.css"
import { createPortal } from "react-dom"

const Popup = ({ onClose, show, popupContent }) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }

    return () => {
      document.body.classList.remove("no-scroll")
    }
  }, [show])

  if (!show) return null

  return createPortal(
    <div className="popup-container3" onClick={onClose}>
      <div className="popup-content3" onClick={(e) => e.stopPropagation()}>
        <button className="popup-close3" onClick={onClose}>
          ✖
        </button>
        <div className="popup-scrollable3">
          <div className="popup-content-inner3">
            <div className="popup-title3">
              <div className="popup-number3">{popupContent.number}</div>
              <div className="popup-header3">{popupContent.title}</div>
            </div>
            {popupContent.texts.map((text, index) => (
              <div key={index} className="popup-text3">
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}

const Footer = (props) => {
  const [showPopup, setShowPopup] = useState(false)
  const [popupContent, setPopupContent] = useState(null)

  const popupContents = [
    {
      number: "",
      title: "",
      texts: [
        <p>Ragione sociale: Domini Agency S.R.L.</p>,
        <p>P.iva: 07070100487</p>,
        <p>Email: info@dominiagency.com</p>,
        <p>Telefono: +39 353 422 6003</p>,
        <p>Indirizzo: Via Arnolfo 32, Firenze 50121 (FI)</p>,
        // etc
      ],
    },

    {
      number: "",
      title: "",
      texts: [
        <div class="popup-section">
          {" "}
          <br></br>
          <h2>Cookies Policy</h2>
          <br></br>
          <p>
            Questo documento contiene informazioni in merito alle tecnologie che consentono a questa Applicazione di raggiungere gli scopi descritti
            di seguito. Tali tecnologie permettono al Titolare di raccogliere e salvare informazioni (per esempio tramite l’utilizzo di Cookie) o di
            utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell’Utente quando quest’ultimo interagisce con questa Applicazione.
          </p>
          <br></br>
          <p>
            Per semplicità, in questo documento tali tecnologie sono sinteticamente definite “Strumenti di Tracciamento”, salvo vi sia ragione di
            differenziare. Per esempio, sebbene i Cookie possano essere usati in browser sia web sia mobili, sarebbe fuori luogo parlare di Cookie nel
            contesto di applicazioni per dispositivi mobili, dal momento che si tratta di Strumenti di Tracciamento che richiedono la presenza di un
            browser. Per questo motivo, all’interno di questo documento il termine Cookie è utilizzato solo per indicare in modo specifico quel
            particolare tipo di Strumento di Tracciamento.
          </p>
          <br></br>
          <p>
            Alcune delle finalità per le quali vengono impiegati Strumenti di Tracciamento potrebbero, inoltre, richiedere il consenso dell’Utente. Se
            viene prestato il consenso, esso può essere revocato liberamente in qualsiasi momento seguendo le istruzioni contenute in questo
            documento.
          </p>
          <br></br>
          <p>
            Questa Applicazione utilizza solo Strumenti di Tracciamento gestiti direttamente dal Titolare (comunemente detti Strumenti di Tracciamento
            “di prima parte”). Durata e scadenza dei Cookie di prima parte e degli altri Strumenti di Tracciamento simili possono variare a seconda di
            quanto impostato dal Titolare. Alcuni di essi scadono al termine della sessione di navigazione dell’Utente.
          </p>
          <br></br>
          <p>
            Attività strettamente necessarie a garantire il funzionamento di questa Applicazione e la fornitura del Servizio. Questa Applicazione
            utilizza Cookie comunemente detti “tecnici” o altri Strumenti di Tracciamento analoghi per svolgere attività strettamente necessarie a
            garantire il funzionamento o la fornitura del Servizio.
          </p>
          <br></br>
          <p>
            Come gestire le preferenze e prestare o revocare il consenso. Esistono vari modi per gestire le preferenze relative agli Strumenti di
            Tracciamento e per prestare o revocare il consenso, ove necessario:
          </p>
          <br></br>
          <p>
            Gli Utenti possono gestire le preferenze relative agli Strumenti di Tracciamento direttamente tramite le impostazioni dei propri
            dispositivi - per esempio, possono impedire l’uso o l’archiviazione di Strumenti di Tracciamento.
          </p>
          <br></br>
          <p>
            In aggiunta, ogni qualvolta l’utilizzo di Strumenti di Tracciamento dipenda da consenso, l’Utente può prestare o revocare tale consenso
            impostando le proprie preferenze all’interno dell’informativa sui cookie o aggiornando tali preferenze tramite il widget privacy per le
            preferenze relative al consenso, se presente.
          </p>
          <br></br>
          <p>
            Grazie ad apposite funzioni del browser o del dispositivo è anche possibile rimuovere Strumenti di Tracciamento precedentemente salvati,
            inclusi quelli utilizzati per il salvataggio delle preferenze relative al consenso inizialmente espresse dall'Utente. Altri Strumenti di
            Tracciamento presenti nella memoria locale del browser possono essere rimossi cancellando la cronologia di navigazione.
          </p>
          <br></br>
          <p>
            Individuare le impostazioni relative agli Strumenti di Tracciamento. Gli Utenti possono, per esempio, trovare informazioni su come gestire
            i Cookie in alcuni dei browser più diffusi ai seguenti indirizzi:
          </p>
          <br></br>
          <ul>
            <li>
              <a href="https://support.google.com/chrome">Google Chrome</a>
            </li>
            <li>
              <a href="https://support.mozilla.org">Mozilla Firefox</a>
            </li>
            <li>
              <a href="https://support.apple.com/safari">Apple Safari</a>
            </li>
            <li>
              <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft Internet Explorer</a>
            </li>
            <li>
              <a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy">Microsoft Edge</a>
            </li>
            <li>
              <a href="https://support.brave.com">Brave</a>
            </li>
            <li>
              <a href="https://www.opera.com/help">Opera</a>
            </li>
          </ul>
          <br></br>
          <p>
            Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per applicazioni mobili disattivandoli tramite le apposite
            impostazioni del dispositivo, quali le impostazioni di pubblicità per dispositivi mobili o le impostazioni relative al tracciamento in
            generale (gli Utenti possono consultare le impostazioni del dispositivo per individuare quella pertinente).
          </p>
          <br></br>
          <p>
            Conseguenze legate al rifiuto dell'utilizzo di Strumenti di Tracciamento. Gli Utenti sono liberi di decidere se permettere o meno
            l'utilizzo di Strumenti di Tracciamento. Tuttavia, si noti che gli Strumenti di Tracciamento consentono a questa Applicazione di fornire
            agli Utenti un'esperienza migliore e funzionalità avanzate (in linea con le finalità delineate nel presente documento). Pertanto, qualora
            l'Utente decida di bloccare l'utilizzo di Strumenti di Tracciamento, il Titolare potrebbe non essere in grado di fornire le relative
            funzionalità.
          </p>
          <br></br>
          <p>Titolare del Trattamento dei Dati: Domini Agency SRL</p>
          <br></br>
          <p>
            Indirizzo email del Titolare: <a href="mailto:info@dominiagency.com">info@dominiagency.com</a>
          </p>
          <br></br>
          <p>
            Data l'oggettiva complessità di identificazione delle tecnologie di tracciamento, gli Utenti sono invitati a contattare il Titolare
            qualora volessero ricevere ulteriori informazioni in merito all'utilizzo di tali tecnologie su questa Applicazione.
          </p>
          <br></br>
        </div>,
      ],
    },
    {
      number: "",
      title: "",
      texts: [
        <div>
          <p>Titolare del Trattamento dei Dati Domini Agency SRL Indirizzo email del Titolare: info@dominiagency.com </p>
          <br></br>
          <p>
            Tipologie di Dati raccolti Il Titolare non fornisce una lista di tipologie di Dati Personali raccolti. Dettagli completi su ciascuna
            tipologia di Dati Personali raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi
            visualizzati prima della raccolta dei Dati stessi.{" "}
          </p>
          <br></br>
          <p>
            I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di
            questa Applicazione. Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l’Utente rifiuta
            di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi
            alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla
            disponibilità del Servizio o sulla sua operatività. Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori sono incoraggiati
            a contattare il Titolare. L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei
            titolari dei servizi terzi utilizzati da questa Applicazione ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle
            ulteriori finalità descritte nel presente documento e nella Cookie Policy. L'Utente si assume la responsabilità dei Dati Personali di
            terzi ottenuti, pubblicati o condivisi mediante questa Applicazione e garantisce di avere il diritto di comunicarli o diffonderli,
            liberando il Titolare da qualsiasi responsabilità verso terzi.{" "}
          </p>
          <br></br>
          <p>
            Modalità e luogo del trattamento dei Dati raccolti Modalità di trattamento Il Titolare adotta le opportune misure di sicurezza volte ad
            impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali. Il trattamento viene effettuato
            mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate.
            Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questa Applicazione
            (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi
            tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario,
            Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del
            Trattamento.{" "}
          </p>
          <br></br>
          <p>
            Luogo I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano
            localizzate. Per ulteriori informazioni, contatta il Titolare. I Dati Personali dell’Utente potrebbero essere trasferiti in un paese
            diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento
            alla sezione relativa ai dettagli sul trattamento dei Dati Personali. Periodo di conservazione Se non diversamente indicato in questo
            documento, i Dati Personali sono trattati e conservati per il tempo richiesto dalla finalità per la quale sono stati raccolti e potrebbero
            essere conservati per un periodo più lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti.{" "}
          </p>
          <br></br>
          <p>
            Cookie Policy Questa Applicazione fa utilizzo di Strumenti di Tracciamento. Per saperne di più, gli Utenti possono consultare la Cookie
            Policy. Ulteriori informazioni per gli utenti Base giuridica del trattamento Il Titolare tratta Dati Personali relativi all’Utente in caso
            sussista una delle seguenti condizioni: • l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti
            il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso dell’Utente o un’altra delle basi
            giuridiche specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile
            qualora il trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali; • il
            trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di misure precontrattuali; • il trattamento è
            necessario per adempiere un obbligo legale al quale è soggetto il Titolare; • il trattamento è necessario per l'esecuzione di un compito
            di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare; • il trattamento è necessario per il
            perseguimento del legittimo interesse del Titolare o di terzi. È comunque sempre possibile richiedere al Titolare di chiarire la concreta
            base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto
            o necessario per concludere un contratto.{" "}
          </p>
          <br></br>
          <p>
            Ulteriori informazioni sul tempo di conservazione Se non diversamente indicato in questo documento, i Dati Personali sono trattati e
            conservati per il tempo richiesto dalla finalità per la quale sono stati raccolti e potrebbero essere conservati per un periodo più lungo
            a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti. Pertanto: • I Dati Personali raccolti per scopi collegati
            all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.
            • I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di
            tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative
            sezioni di questo documento o contattando il Titolare. Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare
            i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare
            i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità. Al termine del periodo di
            conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione,
            rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.{" "}
          </p>
          <br></br>
          <p>
            Diritti dell’Utente Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare. In particolare, nei
            limiti previsti dalla legge, l’Utente ha il diritto di: • revocare il consenso in ogni momento. L’Utente può revocare il consenso al
            trattamento dei propri Dati Personali precedentemente espresso. • opporsi al trattamento dei propri Dati. L’Utente può opporsi al
            trattamento dei propri Dati quando esso avviene in virtù di una base giuridica diversa dal consenso. • accedere ai propri Dati. L’Utente
            ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei
            Dati trattati. • verificare e chiedere la rettificazione. L’Utente può verificare la correttezza dei propri Dati e richiederne
            l’aggiornamento o la correzione. • ottenere la limitazione del trattamento. L’Utente può richiedere la limitazione del trattamento dei
            propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione. • ottenere la cancellazione o
            rimozione dei propri Dati Personali. L’Utente può richiedere la cancellazione dei propri Dati da parte del Titolare. • ricevere i propri
            Dati o farli trasferire ad altro titolare. L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile
            da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. • proporre
            reclamo. L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede
            giudiziale.{" "}
          </p>
          <br></br>
          <p>
            Gli Utenti hanno diritto di ottenere informazioni in merito alla base giuridica per il trasferimento di Dati all'estero incluso verso
            qualsiasi organizzazione internazionale regolata dal diritto internazionale o costituita da due o più paesi, come ad esempio l’ONU, nonché
            in merito alle misure di sicurezza adottate dal Titolare per proteggere i loro Dati. Dettagli sul diritto di opposizione Quando i Dati
            Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un
            interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.
            Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento in
            qualsiasi momento, gratuitamente e senza fornire alcuna motivazione. Qualora gli Utenti si oppongano al trattamento per finalità di
            marketing diretto, i Dati Personali non sono più oggetto di trattamento per tali finalità. Per scoprire se il Titolare tratti Dati con
            finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.{" "}
          </p>
          <br></br>
          <p>
            Come esercitare i diritti Per esercitare i propri diritti, gli Utenti possono indirizzare una richiesta ai recapiti del Titolare indicati
            in questo documento. La richiesta può essere depositate gratuitamente e il Titolare risponderà nel più breve tempo possibile, in ogni caso
            entro un mese, fornendo all’Utente tutte le informazioni previste dalla legge. Eventuali rettifiche, cancellazioni o limitazioni del
            trattamento saranno comunicate dal Titolare a ciascuno dei destinatari, se esistenti, a cui sono stati trasmessi i Dati Personali, salvo
            che ciò si riveli impossibile o implichi uno sforzo sproporzionato. Il Titolare comunica all'Utente tali destinatari qualora egli lo
            richieda.{" "}
          </p>
          <br></br>
          <p>
            Ulteriori informazioni sul trattamento Difesa in giudizio I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in
            giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questa Applicazione o dei
            Servizi connessi da parte dell’Utente. L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati
            per ordine delle autorità pubbliche.{" "}
          </p>
          <br></br>
          <p>
            Informative specifiche Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa Applicazione
            potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di
            Dati Personali. Log di sistema e manutenzione Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli
            eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono
            contenere anche Dati Personali, quali l’indirizzo IP Utente.{" "}
          </p>
          <br></br>
          <p>
            Informazioni non contenute in questa policy Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere
            richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto. Modifiche a questa privacy policy Il
            Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli
            Utenti su questa pagina e, se possibile, su questa Applicazione nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica
            agli Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con frequenza questa pagina,
            facendo riferimento alla data di ultima modifica indicata in fondo. Qualora le modifiche interessino trattamenti la cui base giuridica è
            il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.{" "}
          </p>
          <br></br>
        </div>,
      ],
    },
  ]

  return (
    <div className={`footer-container ${props.rootClassName} `}>
      <Popup
        onClose={() => {
          setShowPopup(false)
          setPopupContent(null)
        }}
        show={showPopup}
        popupContent={popupContent}
      />

      <footer className="footer-footer">
        <div className="footer-indirizzo">
          <img alt="logo" src="/playground_assets/raggruppa%2031-200w.png" className="footer-image" />
          <span className="footer-text">
            <span className="">Via Arnolfo 32,</span>
            <br className="footer-text2"></br>
            <span className="">50121 firenze, italia</span>
          </span>
        </div>
        <div className="footer-links-footer">
          <span
            onClick={() => {
              setShowPopup(true)
              setPopupContent(popupContents[0]) // or any content you want to display
            }}
            className="footer-text4"
          >
            DOMINI AGENCY SRL
          </span>
          <span
            onClick={() => {
              setShowPopup(true)
              setPopupContent(popupContents[1]) // or any content you want to display
            }}
            className="footer-text6"
          >
            COOKIES POLICY
          </span>
          <span
            onClick={() => {
              setShowPopup(true)
              setPopupContent(popupContents[2]) // or any content you want to display
            }}
            className="footer-text8"
          >
            PRIVACY
          </span>
        </div>
        <div className="footer-icon-group">
          <a href="https://www.instagram.com/domini.agency/?hl=en" target="_blank">
            <img alt="logo" src="/playground_assets/iconainstagram.svg" className="footer-instagram" />
          </a>
          <a href="https://it.linkedin.com/company/domini-agency-srls" target="_blank">
            <img alt="logo" src="/playground_assets/iconalinkedin.svg" className="footer-linkedin" />
          </a>
          <a href="https://wa.me/+393534226003" target="_blank">
            <img alt="logo" src="/playground_assets/iconawhatsapp.svg" className="footer-whatsapp" />
          </a>
        </div>
      </footer>
    </div>
  )
}

Footer.defaultProps = {
  text: "Text",
  rootClassName: "",
  text1: "Text",
}

Footer.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  text1: PropTypes.string,
}

export default Footer
